@mixin font-family {
    font-family: 'Poppins';
}

.movie-card {
    position: relative;
    min-width: 20rem;
    min-height: 100%;
    img {
        width: 100%;
        min-height: 100%;
    }
    .info {
        position: absolute;
        padding: 3rem 1rem;
        box-sizing: border-box;
        padding-bottom: 3rem;
        bottom: 0;
        height: 40%;
        transition: 0.3s all;
        background-image: linear-gradient(to bottom, transparent, #000);
        width: 100%;
        @include font-family;
        color: #FDFDFD;
        h4 {
            position: absolute;
            bottom: 1rem;
            width: 90%;
        }
        .movie-information {
            position: absolute;
            opacity: 0;
            left: 0;
            padding: 0 1rem;
            p {
                line-height: 0;
            }
            * {
                height: 0;
            }
        }
    }
    &:hover {
        .info {
            height: 100%;   
        }
        img {
        filter: brightness(0.4); 
        }
        .movie-information {
            opacity: 1;
            transition: 600ms opacity;
            transition-delay: 100ms;
            p {
                line-height: normal;
            }
            * {
                height: 100%;
            }
        }
        h4 {
            position: relative;
        }
    }
    button {
        margin-top: 0.8rem;
        padding: 1rem 3rem;
        @include font-family;
    }
}