@mixin font-family {
    font-family: 'Poppins';
}
.home-body {
    background-color: #111;
    height: 100vh;
    width: 100%;
    @include font-family;
    .main-body {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        h1 {
            color: rgba(255, 63, 52,1.0);
            margin-bottom: 5rem;
            font-weight: 700;
            font-style: italic;
        }
        .options {
            display: flex;
            column-gap: 3rem;
            font-size: 1.3rem;
            font-weight: bold;
            &.animating {
                animation: moveUp 0.3s linear forwards;
            }
        }
        .button {
            opacity: 0;
            &.animatingButton {
                animation: animateButton 0.4s 0.3s linear forwards;
            }
        }
    }
}

@keyframes moveUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-3rem);
    }
}

@keyframes animateButton {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: translateY(-1rem);
    }
}