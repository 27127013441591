.card-body {
    height: 14rem;
    width: 14rem;
    border-radius: 1rem;
    background-color: #1B1B1B;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 1rem;
    .text {
        color: #FDFBF9;
    }
    &.activated {
        border: 2px solid rgba(255, 63, 52,1.0);
    }
}