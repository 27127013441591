.flex-view {
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    display: flex;
    height: 100vh;
    width: 100%;
    &>div {
        width: 20%;
        img {
            width:20rem;
            height:100%;
            width: auto;
            filter: brightness(0.2);
        }
        .card {
            position: absolute;
            top: 0;
            color: white;
            padding: 0 2em;
            height: 100%;
            h2 {
                margin-top: 4em;
                font-style: italic;
                width: 15vw;
            }
            p {
                width: 15vw;
            }
            .links {
                display: flex;
                flex-direction:column;
                justify-content: flex-end;
                row-gap: 1em;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 20vw;
                height: 50%;
                background-image: linear-gradient(to bottom, transparent, #000);
                padding: 8em 2em;
            }
        }
    }
}